import React from 'react';
import Layout, { DotSection, Content } from '@layout';
import PageHeadline from '@components/PageHeadline';
import ContactForm from './ContactForm';
import ContactLink from '@components/ContactLink';
import useSiteMetadata from '@hooks/useSiteMetadata';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './Contact.module.scss';

export default function Contact() {
  const { email, phone } = useSiteMetadata();

  return (
    <Layout title="Contact Us">
      <DotSection className={styles.wrapper}>
        <Content className={styles.contentWrapper}>
          <div className={styles.content}>
            <PageHeadline
              title="Let's build something"
              pretitle="Contact Us"
              className={styles.headline}
              isCentered={false}
              dividerMaxWidth={200}
            />

            <div className={styles.text}>
              <p>
                We would love to speak with you about your project and help
                determine the next step. We look forward to hearing from
                you.
              </p>
            </div>

            <div className={styles.contactInfo}>
              <ContactLink email={email} />
              <ContactLink phone={phone} />
            </div>

            <div className={styles.imageWrapper}>
              <StaticImage
                src="drawing.png"
                alt=""
                layout="fixed"
                width={1200}
                objectPosition="right top"
                quality={60}
                placeholder="none"
                formats={['auto', 'webp', 'avif']}
              />
            </div>
          </div>

          <ContactForm />
        </Content>
      </DotSection>
    </Layout>
  );
}
