import React, { useMemo } from 'react';
import * as styles from './ContactForm.module.scss';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { startsWithVowel } from '@helpers';

export default function Field({
  name,
  type = 'text',
  label,
  className,
  fieldClassName,
  errors,
  errorLabel,
  required = true,
  register,
  isLoading,
  ...restProps
}) {
  const id = `${name.trim().toLowerCase().replace(/ +/g, '-')}-input`;

  label = label ?? name;
  errorLabel = (errorLabel ?? name).toLowerCase();

  const validationArgs = useMemo(() => {
    const args = {};

    if (required) {
      const word = startsWithVowel(errorLabel) ? 'an' : 'a';
      args.required = {
        value: true,
        message: `Please enter ${word} ${errorLabel}`
      };
    }

    if (type === 'tel') {
      args.pattern = {
        value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        message: 'Please enter a valid phone number'
      };
    } else if (type === 'email') {
      args.pattern = {
        value: /(.+)@(.+){2,}\.(.+){2,}/,
        message: 'Please enter a valid email address'
      };
    }

    return args;
  }, [errorLabel, required, type]);

  const props = {
    id,
    className,
    name,
    required,
    readOnly: isLoading ? true : null,
    ...register(name, validationArgs),
    ...restProps
  };

  return (
    <div
      className={classNames(styles.field, fieldClassName, {
        [styles.fullWidth]: type === 'textarea'
      })}
    >
      <label
        htmlFor={id}
        className={styles.label}
      >
        {label}
      </label>

      {type === 'textarea' ? (
        <textarea {...props} />
      ) : (
        <React.Fragment>
          {type === 'tel' ? (
            <NumberFormat
              type="tel"
              format="(###) ###-####"
              {...props}
            />
          ) : (
            <input
              type={type}
              {...props}
            />
          )}
        </React.Fragment>
      )}
      {errors[name] && (
        <div className={styles.validationWrapper}>
          <div className={styles.validation}>
            <p>{errors[name].message}</p>
          </div>
        </div>
      )}
    </div>
  );
}
