import React, { useState, useCallback } from 'react';
import { H2 } from '@headline';
import { Button } from '@button';
import Divider from '@components/Divider';
import * as styles from './ContactForm.module.scss';
import Field from './Field';
import useWindowSize from '@hooks/useWindowSize';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

const formName = 'Contact Form';

export default function ContactForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { width: windowWidth } = useWindowSize();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ reValidateMode: 'onBlur' });

  const onSubmit = useCallback(async data => {
    setIsLoading(true);

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': formName, ...data })
      });

      setHasSubmitted(true);
      setHasError(false);
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
  }, []);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.submitted]: hasSubmitted
      })}
    >
      <Divider
        direction={windowWidth > 860 ? 'vertical' : 'horizontal'}
        className={styles.divider}
      />
      {!hasSubmitted ? (
        <React.Fragment>
          <H2>Send us a message</H2>
          {hasError && (
            <div className={styles.error}>
              There was a problem submitting your request, please try again
              later.
            </div>
          )}
          <form
            name={formName}
            method="post"
            data-netlify="true"
            className={styles.form}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className={styles.fields}>
              {fields.map((props, index) => (
                <Field
                  key={index}
                  errors={errors}
                  isLoading={isLoading}
                  register={register}
                  {...props}
                />
              ))}
            </div>

            <Button isLoading={isLoading}>Send Message</Button>
          </form>
        </React.Fragment>
      ) : (
        <div className={styles.success}>
          <svg
            className={styles.checkmark}
            viewBox="0 0 52 52"
          >
            <circle
              cx="26"
              cy="26"
              r="25"
              fill="none"
            ></circle>
            <path
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            ></path>
          </svg>
          <p>
            Thank you, your submission has been received. We will get back
            to you as soon as possible.
          </p>
        </div>
      )}
    </div>
  );
}

const fields = [
  {
    name: 'Name',
    label: 'Your name',
    placeholder: 'Enter your full name'
  },
  {
    name: 'Company Name',
    placeholder: 'Enter your company name'
  },
  {
    name: 'Phone Number',
    type: 'tel',
    placeholder: 'Enter your phone number'
  },
  {
    name: 'Email',
    label: 'Email Address',
    type: 'email',
    placeholder: 'Enter your email address'
  },
  {
    name: 'Message',
    type: 'textarea',
    placeholder: 'Enter your message'
  }
];

function encode(data) {
  return Object.keys(data)
    .map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
    )
    .join('&');
}
