export function isSSR() {
  return typeof window === 'undefined' || typeof document === 'undefined';
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function isIOS() {
  return isBrowser() && /iPhone|iPad|iPod/i.test(navigator.platform);
}

export function isAndroid() {
  return (
    isBrowser() &&
    (/Android/i.test(navigator.userAgent) ||
      /webOS/i.test(navigator.userAgent))
  );
}

export function startsWithVowel(str) {
  return /^[aeiou]/i.test(str);
}
